import PropTypes from "prop-types";
import styles from "./Header.module.scss";
import skipt from "../../../assets/images/Skipt_logo.png";
import { Link } from "react-router-dom";

const Header = props => {
  const { variant } = props;
  return (
    <div
      className={`${styles.Header} ${
        styles[`Header__${variant}`]
      } `}
    >
      <header>
        <Link to={"/"}>
          <img src={skipt} alt="skipt" />
        </Link>
        <div className="Header__Line"></div>
      </header>
    </div>
  );
};

Header.defaultProps = {
  variant: "default",
  className: "",
};

Header.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Header;

import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

let selectedRestaurant = localStorage.getItem(
  "persistantRequest",
);

selectedRestaurant = JSON.parse(selectedRestaurant);

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: {
    loading: false,
    restaurantsData: {},
    lockRestaurant: {},
    selectedRestaurant: {
      restaurantId: selectedRestaurant?._rid ?? "",
      availabilityId: selectedRestaurant?._aid ?? "",
      restaurantAccount: selectedRestaurant?._acc ?? "",
      lockedTime: selectedRestaurant?._lt ?? "",
      name: selectedRestaurant?._name ?? "",
      date: selectedRestaurant?._date ?? "",
      people: selectedRestaurant?._people ?? "",
      price: selectedRestaurant?._price ?? "",
    },
  },
  reducers: {
    updateSelectedRestaurant(state, action) {
      localStorage.setItem(
        "persistantRequest",
        JSON.stringify(action.payload),
      );
      if (action.payload?._rid) {
        state.selectedRestaurant.restaurantId =
          action.payload._rid;
      }

      if (action.payload?._aid) {
        state.selectedRestaurant.availabilityId =
          action.payload._aid;
      }

      if (action.payload?._acc) {
        state.selectedRestaurant.restaurantAccount =
          action.payload._acc;
      }

      if (action.payload?._lt) {
        state.selectedRestaurant.lockedTime =
          action.payload._lt;
      }
      if (action.payload?._name) {
        state.selectedRestaurant.name =
          action.payload._name;
      }
      if (action.payload?._date) {
        state.selectedRestaurant.date =
          action.payload._date;
      }
      if (action.payload?._people) {
        state.selectedRestaurant.people =
          action.payload._people;
      }
      if (action.payload?._price) {
        state.selectedRestaurant.price =
          action.payload._price;
      }
    },
  },
  extraReducers: {
    /**
     * Restaurant List
     */
    [actions.get_restaurants.pending]: state => {
      state.loading = true;
    },
    [actions.get_restaurants.fulfilled]: (
      state,
      action,
    ) => {
      if (!action.payload) {
        state.loading = false;
        return;
      }

      state.restaurantsData.items = action.payload;
    },
    [actions.get_restaurants.rejected]: state => {
      state.loading = false;
    },

    /**
     * Lock Restaurant
     */
    [actions.lock_restaurant.pending]: state => {
      state.loading = true;
    },
    [actions.lock_restaurant.fulfilled]: (
      state,
      action,
    ) => {
      if (!action.payload) {
        state.loading = false;
        return;
      }

      state.lockRestaurant = action.payload;
    },
    [actions.lock_restaurant.rejected]: state => {
      state.loading = false;
    },
  },
});

export const { updateSelectedRestaurant } =
  restaurantSlice.actions;

export default restaurantSlice.reducer;

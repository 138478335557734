import React, { Fragment } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import PropTypes from "prop-types";
import history from "./history";
import MainLayout from "../layouts/MainLayout/MainLayout";

/**
 * Route listed below
 */

const routes = [
  {
    path: "/",
    component: React.lazy(
      () => import("../pages/RestaurantListing"),
    ),
    layout: MainLayout,
  },
  {
    path: "/payment-checkout",
    component: React.lazy(
      () => import("../pages/PaymentCheckout"),
    ),
    layout: MainLayout,
  },
  {
    path: "/payment-success",
    component: React.lazy(
      () => import("../pages/PaymentSuccess"),
    ),
    layout: MainLayout,
  },
  {
    path: "/legal",
    component: React.lazy(
      () => import("../pages/LegalPage"),
    ),
    layout: MainLayout,
  },
  {
    path: "/network-error",
    component: React.lazy(
      () => import("../pages/ErrorPage"),
    ),
    layout: MainLayout,
  },

  {
    path: "/something-went-wrong",
    component: React.lazy(
      () => import("../pages/ErrorPage"),
    ),
    layout: MainLayout,
  },
  {
    path: "/payment-error",
    component: React.lazy(
      () => import("../pages/ErrorPage"),
    ),
    layout: MainLayout,
  },
  {
    path: "/booking-error",
    component: React.lazy(
      () => import("../pages/ErrorPage"),
    ),
    layout: MainLayout,
  },
  {
    path: "*",
    component: React.lazy(
      () => import("../pages/NotFound"),
    ),
    layout: MainLayout,
  },
];

const Element = ({ component, layout }) => {
  const Component = component;
  const Layout = layout || Fragment;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

Element.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.func,
};

const createRoutes = routes => {
  return routes.map(route => {
    if (route.routes?.length) {
      const indexRoute = route.routes.find(r => r.index);
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<Element {...route} />}
          index={route.index}
        >
          {!!indexRoute && (
            <Route
              index
              element={<Element {...indexRoute} />}
            />
          )}
          {createRoutes(route.routes)}
        </Route>
      );
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={<Element {...route} />}
        index={route.index}
      />
    );
  });
};

export default function AppRouter() {
  return (
    <Router location={history.location} navigator={history}>
      <Routes>{createRoutes(routes)}</Routes>
    </Router>
  );
}

import PropTypes from "prop-types";
import styles from "./Footer.module.scss";

const Footer = props => {
  const { variant } = props;
  let year = new Date().getFullYear();
  const isPaymentCheckout =
    location.pathname === "/payment-checkout";
  const linkTarget = isPaymentCheckout ? "_blank" : "_self";
  return (
    <footer
      className={`${styles.Footer}  ${
        styles[`Footer__${variant}`]
      }`}
    >
      <div className="Footer__Line"></div>
      <div className="Footer__Main">
        <div className="Footer__Company">
          SKIPT - @{year} All rights reserved
        </div>
        <nav role="navigation" className="Footer__Nav">
          <ul role="menu">
            <li role="menu item">
              <a
                href="/legal"
                title="Redirects to Legal Page"
                className="Footer__Legal"
                target={linkTarget}
              >
                Legal
              </a>
            </li>

            <li role="menu item">
              <a
                href="/legal"
                title="Redirects to Privacy"
                target={linkTarget}
              >
                Privacy
              </a>
            </li>

            <li role="menu item">
              <a
                href="mailto:info@getskipt.com"
                title="info@getskipt.com"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  variant: "default",
  className: "",
};

Footer.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Footer;

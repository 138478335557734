import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: "errorMessage",
  initialState: {
    data: "",
  },
  reducers: {
    updateErrorMessage(state, action) {
      if (action.payload) {
        state.data = action.payload;
      }
    },
  },
});

export const { updateErrorMessage } = messageSlice.actions;

export default messageSlice.reducer;

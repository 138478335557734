import {
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import restaurantSlice from "./restaurant";
import paymentSlice from "./payment";
import messageSlice from "./message";

const reducers = combineReducers({
  restaurant: restaurantSlice,
  payment: paymentSlice,
  errorMessage: messageSlice,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [""],
};

const persistedReducer = persistReducer(
  persistConfig,
  reducers,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };

import api from "../utils/api";

export const get_restaurants = (data, options) => {
  return api({
    url: "get_resturants",
    method: "GET",
    data,
    ...options,
  });
};

export const lock_restaurant = (data, options) => {
  return api({
    url: `lock_resturant_availability/${data._rid}/${data._aid}`,
    method: "GET",
    data,
    ...options,
  });
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import * as paymentsAPI from "../../api/payment";

export const create_payment_intent = createAsyncThunk(
  "restaurant/create_payment_intent",
  async options => {
    const res =
      await paymentsAPI.create_payment_intent(options);
    return res;
  },
);

export const create_booking = createAsyncThunk(
  "restaurant/create_booking",
  async options => {
    const res = await paymentsAPI.create_booking(options);
    return res;
  },
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_Header__6g428 {
  display: none;
}
@media screen and (min-width:992px) {
  .Header_Header__6g428 {
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.Header_Header__6g428 img {
  width: 130px;
  height: 38px;
  object-fit: cover;
}
.Header_Header__6g428 .Header__Line {
  height: 1px;
  border-bottom: 1px solid #465551;
  position: absolute;
  left: 0;
  right: 0;
  top: 84px;
}

.Header_Header__start__gkuFJ {
  cursor: pointer;
}
@media screen and (min-width:992px) {
  .Header_Header__start__gkuFJ {
    display: flex;
    justify-content: start;
    padding-left: 0 px;
  }
}

.Header_Header__payment__tzzYQ {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 23px;
}
@media screen and (min-width:768px) {
  .Header_Header__payment__tzzYQ {
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media screen and (min-width:992px) {
  .Header_Header__payment__tzzYQ {
    margin-bottom: 0;
    margin-top: 0;
    height: 84px;
  }
}
.Header_Header__payment__tzzYQ img {
  height: 24px;
  object-fit: cover;
  width: auto;
}
@media screen and (min-width:992px) {
  .Header_Header__payment__tzzYQ img {
    height: 38px;
  }
}
.Header_Header__payment__tzzYQ .Header__Line {
  display: none;
}
@media screen and (min-width:1320px) {
  .Header_Header__payment__tzzYQ .Header__Line {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Header/Header.module.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;AAHJ;AAII;EAFF;IAGI,YAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;EADJ;AACF;AAEI;EACE,YAAA;EACA,YAAA;EACA,iBAAA;AAAN;AAEI;EACE,WAAA;EACA,gCAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AAAN;;AAME;EACE,eAAA;AAHJ;AAII;EAFF;IAGI,aAAA;IACA,sBAAA;IACA,kBAAA;EADJ;AACF;;AAWE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AARJ;AAUI;EANF;IAOI,wBAAA;IAAA,mBAAA;EAPJ;AACF;AAQI;EATF;IAUI,gBAAA;IACA,aAAA;IACA,YAAA;EALJ;AACF;AAMI;EACE,YAAA;EACA,iBAAA;EACA,WAAA;AAJN;AAKM;EAJF;IAKI,YAAA;EAFN;AACF;AAII;EACE,aAAA;AAFN;AAGM;EAFF;IAGI,cAAA;EAAN;AACF","sourcesContent":["@import \"src/styles/shared/_variables\";\n@import \"src/styles/shared/_mixins\";\n\n.Header {\n  :global {\n    display: none;\n    @media #{$lg} {\n      height: 84px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n    img {\n      width: 130px;\n      height: 38px;\n      object-fit: cover;\n    }\n    .Header__Line {\n      height: 1px;\n      border-bottom: 1px solid $colorDetails;\n      position: absolute;\n      left: 0;\n      right: 0;\n      top: 84px;\n    }\n  }\n}\n\n.Header__start {\n  :global {\n    cursor: pointer;\n    @media #{$lg} {\n      display: flex;\n      justify-content: start;\n      padding-left: 0 px;\n    }\n  }\n}\n\n.Header__default {\n  :global {\n  }\n}\n\n.Header__payment {\n  :global {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 25px;\n    margin-top: 23px;\n\n    @media #{$md} {\n      height: fit-content;\n    }\n    @media #{$lg} {\n      margin-bottom: 0;\n      margin-top: 0;\n      height: 84px;\n    }\n    img {\n      height: 24px;\n      object-fit: cover;\n      width: auto;\n      @media #{$lg} {\n        height: 38px;\n      }\n    }\n    .Header__Line {\n      display: none;\n      @media #{$xxl} {\n        display: block;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Header_Header__6g428`,
	"Header__start": `Header_Header__start__gkuFJ`,
	"Header__payment": `Header_Header__payment__tzzYQ`
};
export default ___CSS_LOADER_EXPORT___;

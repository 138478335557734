import { createAsyncThunk } from "@reduxjs/toolkit";
import * as restaurantsAPI from "../../api/restaurant";

export const get_restaurants = createAsyncThunk(
  "restaurant/get_restaurants",
  async options => {
    const res =
      await restaurantsAPI.get_restaurants(options);
    return res;
  },
);

export const lock_restaurant = createAsyncThunk(
  "restaurant/lock_restaurant",
  async options => {
    const res =
      await restaurantsAPI.lock_restaurant(options);
    return res;
  },
);

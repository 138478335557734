import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    loading: false,
    userData: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
    },
  },
  reducers: {
    updateUserData(state, action) {
      if (action.payload?.firstName) {
        state.userData.firstName = action.payload.firstName;
      }

      if (action.payload?.lastName) {
        state.userData.lastName = action.payload.lastName;
      }

      if (action.payload?.phoneNumber) {
        state.userData.phoneNumber =
          action.payload.phoneNumber;
      }

      if (action.payload?.email) {
        state.userData.email = action.payload.email;
      }
    },
  },
  extraReducers: {
    /**
     * Create Payment Intent
     */
    [actions.create_payment_intent.pending]: state => {
      state.loading = true;
    },
    [actions.create_payment_intent.fulfilled]: (
      state,
      action,
    ) => {
      if (!action.payload) {
        state.loading = false;
        return;
      }
    },
    [actions.create_payment_intent.rejected]: state => {
      state.loading = false;
    },
    /**
     * Create Booking
     */
    [actions.create_booking.pending]: state => {
      state.loading = true;
    },
    [actions.create_booking.fulfilled]: (state, action) => {
      if (!action.payload) {
        state.loading = false;
        return;
      }
    },
    [actions.create_booking.rejected]: state => {
      state.loading = false;
    },
  },
});

export const { updateUserData } = paymentSlice.actions;

export default paymentSlice.reducer;

import api from "../utils/api";

export const create_payment_intent = (data, options) => {
  return api({
    url: "create_payment_intent",
    method: "POST",
    data,
    ...options,
  });
};

export const create_booking = (data, options) => {
  return api({
    url: "create_resturant_booking",
    method: "POST",
    data,
    ...options,
  });
};

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./InternetError.module.scss";

const InternetError = props => {
  const { variant, className } = props;

  const [windowHeight, setWindowHeight] = useState(
    window.innerHeight,
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    document.documentElement.style.setProperty(
      "--window-height",
      `${windowHeight}px`,
    );

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowHeight]);

  return (
    <div
      className={`${styles.InternetError} ${
        styles[`InternetError__${variant}`]
      } ${className}`}
    >
      <div className="InternetError__Main">
        <div className="InternetError__Container">
          <div className="InternetError__Circle1">
            <div className="InternetError__Circle2">
              <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NiIgaGVpZ2h0PSIzOCIgdmlld0JveD0iMCAwIDQ2IDM4IiBmaWxsPSJub25lIj4NCjxwYXRoIGQ9Ik0zMC4xMjM5IDE1QzMyLjg2MDUgMTUuODUyNiAzNS40MjQ3IDE3LjIzODIgMzcuNjYgMTkuMU00NC42NTk4IDExLjk5OTlDMzguODE1IDYuODQ3ODIgMzEuMjkxMyA0LjAwNTE5IDIzLjQ5OTggNC4wMDUxOUMyMi4yODk4IDQuMDA1MTkgMjEuMDg2MyA0LjA3Mzc1IDE5Ljg5NDQgNC4yMDg4N00xNi41NTk2IDI2LjIyQzE4LjU5IDI0Ljc3NzUgMjEuMDE4OSAyNC4wMDI1IDIzLjUwOTYgMjQuMDAyNUMyNi4wMDAyIDI0LjAwMjUgMjguNDI5MiAyNC43Nzc1IDMwLjQ1OTYgMjYuMjJNMjMuNSAzMy45OTk5SDIzLjUyTTEuODg2NzIgMTIuNDAxNUM0LjU1MzkzIDkuOTU3MzMgNy42MzY3OCA3Ljk1OTQ0IDExLjAxNyA2LjUyNjA2TTguOTYzODcgMTkuNDg1OUMxMS43NTg3IDE3LjAyMzkgMTUuMTgzNCAxNS4yNjAyIDE4Ljk2NTMgMTQuNDY3OE0zMC44OTY3IDI2LjU1MDFDMjguODU4NCAyNC45NTI1IDI2LjI5MDMgMjMuOTk5OSAyMy40OTk4IDIzLjk5OTlDMjAuNjY3IDIzLjk5OTkgMTguMDYzNCAyNC45ODE1IDE2LjAxMDcgMjYuNjIzMU01LjUgMC45OTk5MzlMNDEuNSAzNi45OTk5IiBzdHJva2U9IiNENUVGN0YiIHN0eWxlPSJzdHJva2U6I0Q1RUY3RjtzdHJva2U6Y29sb3IoZGlzcGxheS1wMyAwLjgzNTMgMC45MzczIDAuNDk4MCk7c3Ryb2tlLW9wYWNpdHk6MTsiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4=" />
            </div>
          </div>
          <div className="InternetError__TextContainer">
            <h1>No connection available.</h1>
            <div className="InternetError__Logo">
              <picture>
                <source
                  media="(min-width:768px)"
                  srcSet="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NTkiIGhlaWdodD0iNjUxIiB2aWV3Qm94PSIwIDAgNTU5IDY1MSIgZmlsbD0ibm9uZSI+DQogIDxwYXRoIGQ9Ik01NDcuOTM5IDMyOC40MTRIMzk3LjAwNFYxNjQuOTYxSDcxMS43MjhWMEgzOTcuMDA0VjE2NC4yMTFIMjQ2LjA2OUMxMTAuMzgyIDE2NC4yMTEgMCAyNzQuODczIDAgNDEwLjg5NUMwIDU0Ni45MTcgMTEwLjM4MiA2NTcuNTg2IDI0Ni4wNjkgNjU3LjU4NkgzOTcuMDA0VjgyMS4wMzFIODIuMjcyNFY5ODUuOTkySDM5Ny4wMDRWODIxLjc4OUg1NDcuOTM5QzY4My42MjYgODIxLjc4OSA3OTQgNzExLjEyOCA3OTQgNTc1LjEwNUM3OTQgNDM5LjA4MyA2ODMuNjE4IDMyOC40MTQgNTQ3LjkzOSAzMjguNDE0Wk0yNDYuMDY5IDQ5Mi42MjVDMjAxLjExOCA0OTIuNjI1IDE2NC41NDUgNDU1Ljk1OSAxNjQuNTQ1IDQxMC44OTVDMTY0LjU0NSAzNjUuODMgMjAxLjExOCAzMjkuMTcyIDI0Ni4wNjkgMzI5LjE3MkgzOTcuMDA0VjQ5Mi42MjVIMjQ2LjA2OVpNNTQ3LjkzOSA2NTYuODI4SDM5Ny4wMDRWNDkzLjM3NUg1NDcuOTM5QzU5Mi44OSA0OTMuMzc1IDYyOS40NTUgNTMwLjA0MSA2MjkuNDU1IDU3NS4xMDVDNjI5LjQ1NSA2MjAuMTcgNTkyLjg5IDY1Ni44MjggNTQ3LjkzOSA2NTYuODI4WiIgZmlsbD0iI0Q1RUY3RiIgc3R5bGU9ImZpbGw6I0Q1RUY3RjtmaWxsOmNvbG9yKGRpc3BsYXktcDMgMC44MzUzIDAuOTM3MyAwLjQ5ODApO2ZpbGwtb3BhY2l0eToxOyIvPg0KPC9zdmc+"
                />
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTEiIGhlaWdodD0iNDI4IiB2aWV3Qm94PSIwIDAgMjkxIDQyOCIgZmlsbD0ibm9uZSI+DQogIDxwYXRoIGQ9Ik0yNzkuNDkgMTY3LjUzOEgyMDIuNTAyVjg0LjE1MzdIMzYzLjAzNVYwSDIwMi41MDJWODMuNzcwOUgxMjUuNTE0QzU2LjMwMzMgODMuNzcwOSAwIDE0MC4yMjQgMCAyMDkuNjE1QzAgMjc5LjAwNSA1Ni4zMDMzIDMzNS40NjIgMTI1LjUxNCAzMzUuNDYySDIwMi41MDJWNDE4Ljg0Mkg0MS45NjUyVjUwMi45OTZIMjAyLjUwMlY0MTkuMjI5SDI3OS40OUMzNDguNzAxIDQxOS4yMjkgNDA1IDM2Mi43NzYgNDA1IDI5My4zODVDNDA1IDIyMy45OTUgMzQ4LjY5NyAxNjcuNTM4IDI3OS40OSAxNjcuNTM4Wk0xMjUuNTE0IDI1MS4zMDlDMTAyLjU4NiAyNTEuMzA5IDgzLjkzMDMgMjMyLjYwNCA4My45MzAzIDIwOS42MTVDODMuOTMwMyAxODYuNjI1IDEwMi41ODYgMTY3LjkyNSAxMjUuNTE0IDE2Ny45MjVIMjAyLjUwMlYyNTEuMzA5SDEyNS41MTRaTTI3OS40OSAzMzUuMDc1SDIwMi41MDJWMjUxLjY5MUgyNzkuNDlDMzAyLjQxOCAyNTEuNjkxIDMyMS4wNyAyNzAuMzk2IDMyMS4wNyAyOTMuMzg1QzMyMS4wNyAzMTYuMzc1IDMwMi40MTggMzM1LjA3NSAyNzkuNDkgMzM1LjA3NVoiIGZpbGw9IiNENUVGN0YiIHN0eWxlPSJmaWxsOiNENUVGN0Y7ZmlsbDpjb2xvcihkaXNwbGF5LXAzIDAuODM1MyAwLjkzNzMgMC40OTgwKTtmaWxsLW9wYWNpdHk6MTsiLz4NCjwvc3ZnPg==" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InternetError.defaultProps = {
  variant: "default",
  className: "",
};

InternetError.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default InternetError;

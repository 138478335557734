// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_MainLayout__cYsUF {
  position: relative;
}
.MainLayout_MainLayout__cYsUF .Container {
  padding: 0 10px;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
}
@media screen and (min-width:768px) {
  .MainLayout_MainLayout__cYsUF .Container {
    padding: 0 32px;
  }
}
.MainLayout_MainLayout__cYsUF .Container .Content__Wrapper {
  min-height: calc(100vh - 192px);
}`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout/MainLayout.module.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;AAFF;AAII;EACE,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;AAFN;AAIM;EANF;IAOI,eAAA;EADN;AACF;AAEM;EACE,+BAAA;AAAR","sourcesContent":["@import \"src/styles/shared/_variables\";\n@import \"src/styles/shared/_mixins\";\n\n.MainLayout {\n  position: relative;\n  :global {\n    .Container {\n      padding: 0 10px;\n      box-sizing: border-box;\n      max-width: 1440px;\n      margin: 0 auto;\n\n      @media #{$md} {\n        padding: 0 32px;\n      }\n      .Content__Wrapper {\n        min-height: calc(100vh - 192px);\n      }\n    }\n  }\n}\n\n.MainLayout__default {\n  :global {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainLayout": `MainLayout_MainLayout__cYsUF`
};
export default ___CSS_LOADER_EXPORT___;

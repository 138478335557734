import PropTypes from "prop-types";
import React, { Suspense, useEffect } from "react";
import styles from "./MainLayout.module.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer/Footer";
import { useLocation } from "react-router-dom";

const MainLayout = props => {
  const { variant, className } = props;
  let location = useLocation();
  const navigate = useNavigate();
  let isOnline = navigator.onLine;
  useEffect(() => {
    if (!isOnline) {
      navigate("/network-error");
    }
  }, [isOnline]);

  const isHome = location.pathname === "/";
  const isPaymentCheckout =
    location.pathname === "/payment-checkout";
  const isLegal = location.pathname === "/legal";
  const isBookingError =
    location.pathname === "/booking-error";
  const isNetworkError =
    location.pathname === "/network-error";
  const isPaymentError =
    location.pathname === "/payment-error";
  const isUnexpectederror =
    location.pathname === "/something-went-wrong";
  const isPaymentSuccess =
    location.pathname === "/payment-success";

  return (
    <div
      className={`${styles.MainLayout} ${
        styles[`MainLayout__${variant}`]
      } ${className}`}
    >
      <Suspense>
        <div
          style={{
            overflowX: "hidden",
            boxSizing: "border-box",
          }}
        >
          <main>
            <div className="Container">
              <Header
                variant={
                  isHome
                    ? "start"
                    : isPaymentCheckout
                      ? "payment"
                      : ""
                }
              />
              <div className="Content__Wrapper">
                {props.children}
              </div>
              {!isLegal && (
                <Footer
                  variant={
                    isPaymentSuccess ||
                    isUnexpectederror ||
                    isNetworkError ||
                    isPaymentError ||
                    isBookingError
                      ? "Success"
                      : isPaymentCheckout
                        ? "Payment"
                        : isHome
                          ? "Center"
                          : ""
                  }
                />
              )}
            </div>
          </main>
        </div>
      </Suspense>
    </div>
  );
};

MainLayout.defaultProps = {
  variant: "default",
  className: "",
};

MainLayout.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default MainLayout;
